<template>
  <div class="p-3 pb-5 mb-5 list-mdt">
    <div class="d-flex align-mdt_rooms-center">
      <Organization class="mr-2" />
      <p class="robo-24-500 txt-pri mb-0">Hội Chẩn Hội trường</p>
    </div>
    <!-- body -->
    <div class="row mt-4 body-list">
      <!-- left -->
      <div class="col-md-6">
        <div class="w-100 mb-4" v-if="showList">
          <p class="robo-18-500 txt-black">
            Danh sách phòng Hội chẩn Hội trường
          </p>
          <div
            class="room-item cursor-pointer"
            v-for="item in mdt_rooms"
            :key="item.id"
            :class="showing_mdt_room_id == item.id ? 'active-item' : ''"
            @click="onShowMdtRoom(item.id)"
          >
            <p
              :class="
                showing_mdt_room_id == item.id ? 'tex-white' : 'txt-black'
              "
              class="robo-18-500 mb-1 max-line-1"
            >
              {{ item.name }}
            </p>
            <p
              :class="
                showing_mdt_room_id == item.id ? 'tex-white' : 'txt-grey-600'
              "
              class="robo-16-400 mb-0 max-line-1"
            >
              {{ mdtUtils.getTimeStringDetail(item) }}
            </p>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="text-center" :class="fullCol12 ? 'col-md-12' : 'col-md-6'">
        <img
          src="../../../public/assets/images/MDT/Frame.png"
          class="img-1 mb-3"
          alt=""
        />
        <p class="text-center robo-24-500 txt-black fw-700">
          Hội chẩn đạt chuẩn bảo mật HIPAA dành cho Y tế
        </p>
        <p class="text-center robo-14-400 txt-black">
          Nền tảng an toàn, bảo mật để tổ chức Hội chẩn. Hệ thống công cụ toàn
          diện và có tích hợp PACS Viewer
        </p>
        <div class="dropdown">
          <button
            class="btn bg-pri bd-pri text-white mt-3"
            type="button"
            @click.prevent="goToCreateMdt"
          >
            <CreateMeet />
            Tạo mới
          </button>
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click.prevent="goToCreateMdt"
              >Phòng hội chẩn</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <!-- end body -->
    <div
      class="d-flex align-mdt_rooms-center justify-content-center"
      v-if="loading"
    >
      <div class="spinner-border mt-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="mdt-room-detail" v-show="showing_mdt_room">
      <div class="row m-0">
        <div
          class="d-none d-lg-block col-lg-2"
          @click="hideMdtRoomDetail"
        ></div>
        <div class="col-md-12 col-lg-10 content-room">
          <div class="_header">
            <span></span>
            <div class="d-flex align-items-center">
              <svg
                v-if="isMaster"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer mx-1"
                @click="goToUpdateMdt"
              >
                <path
                  d="M14.059 9.02L14.979 9.94L5.91902 19H4.99902V18.08L14.059 9.02ZM17.659 3C17.409 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3ZM14.059 6.19L2.99902 17.25V21H6.74902L17.809 9.94L14.059 6.19Z"
                  fill="black"
                />
              </svg>

              <!-- <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer mx-2"
              >
                <path
                    d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
                    fill="black"
                />
              </svg> -->
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer mx-1"
                @click="hideMdtRoomDetail"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <MdtRoomDetail
            :showing_room="showing_mdt_room"
            :loading_room="loading_room"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import MdtRoomDetail from './OrganizationMdtRoomDetail.vue'
import { CreateMeet, Organization } from '@/components/Icon'
import moment from 'moment'

export default {
  name: 'OrganizationListMdt',
  components: { CreateMeet, Organization, MdtRoomDetail },
  data () {
    return {
      mdt_rooms: [],
      search: '',
      page: 1,
      loading: false,
      loading_room: false,
      showing_mdt_room_id: null,
      showing_mdt_room: null,
      mdtUtils,
      usr_member: {},
      user: {}
    }
  },
  mounted () {
    this.getPublicMtdRooms()
    this.user = appUtils.getLocalUser()
    if (this.$route.query.mdtid) {
      this.onShowMdtRoom(this.$route.query.mdtid)
      setTimeout(() => {
        let query = Object.assign({}, this.$route.query)
        delete query.mdtid
        this.$router.replace({ query })
      }, 100)
    }
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },
    showList () {
      return this.mdt_rooms.length && !this.loading
    },
    fullCol12 () {
      return !this.mdt_rooms.length && !this.loading
    },
    isMaster () {
      return this.usr_member?.role === 4 || this.usr_member?.role === 1
    }
  },
  methods: {
    async getPublicMtdRooms () {
      const params = {
        organization_id: this.$globalOrg?.id,
        page: this.page,
        limit: 200
      }
      const res = await this.DoctorRequest.getPublicMtdRooms(params)
      this.mdt_rooms = res.data.data
    },
    goToCreateMdt () {
      this.$router.push({ name: 'OrganizationCreateMtd' })
    },
    onShowMdtRoom (id) {
      this.showMdtRoom(id)
    },
    async showMdtRoom (id) {
      this.showing_mdt_room_id = id
      this.loading_room = true
      try {
        const res = await this.DoctorRequest.getPublicMtdRoomDetails(id)
        this.showing_mdt_room = res.data
        this.usr_member = res?.data?.members?.find(m => m.user_id === this.user?.id)
      } catch (e) {
        this.$toast.open({
          message: 'Không tìm thấy thông tin phòng',
          type: 'warning'
        })
      } finally {
        this.loading_room = false
      }
    },
    getDateString (room) {
      if (!room) return
      let now = moment()
      let date = moment(room.date)
      let type = room.repeat_type
      let time = room.start_time
      if (time) {
        time = time.substring(0, 5)
      }
      if (type) {
        if (date.clone().unix() <= now.clone().unix()) {
          if (type === 1) {
            return mdtUtils.getNameOfDay(now.day()) + ' (' + now.clone().format('DD/MM/YYYY') + ' - ' + time + ')'
          } else if (type === 2) {
            if (date.day() === now.day()) {
              return mdtUtils.getNameOfDay(now.day()) + ' (' + now.clone().format('DD/MM/YYYY') + ' - ' + time + ')'
            } else {
              let nextDate = mdtUtils.getDateNextWeekByDay(date.day())
              return mdtUtils.getNameOfDay(nextDate.day()) + ' (' + nextDate.format('DD/MM/YYYY') + ' - ' + time + ')'
            }
          }
        } else {
          return mdtUtils.getNameOfDay(date.clone().day()) + ' (' + date.clone().format('DD/MM/YYYY') + ' - ' + time + ')'
        }
      } else {
        return mdtUtils.getNameOfDay(date.day()) + ' (' + date.clone().format('DD/MM/YYYY') + ' - ' + time + ')'
      }
    },
    hideMdtRoomDetail () {
      this.showing_mdt_room = null
      this.showing_mdt_room_id = null
    },
    goToUpdateMdt () {
      if (!this.showing_mdt_room) return
      this.$router.push({ name: 'OrganizationMdtRoomUpdate', params: { id: this.showing_mdt_room.id } })
    }
  }
}
</script>

<style scoped>
.room-item {
  margin-bottom: 16px;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background: #fff;
  box-shadow: 0 8px 8px 0 rgba(196, 196, 196, 0.11),
    0 12px 18px 0 rgba(184, 184, 184, 0.11);
}
.active-item {
  background-color: #20419b;
  color: white;
}
.mdt-room-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1041;
}
.mdt-room-detail ._header {
  display: flex;
  justify-content: space-between;
}
.mdt-room-detail .content-room {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding-left: 32px;
  padding-right: 0;
  padding-top: 16px;
  background: #fff;
  height: 100vh;
}
.body-list {
  min-height: 500px;
}
.img-1 {
  width: 100%;
  max-width: 420px;
  height: auto;
  margin: 0 auto;
}
</style>